@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Montserrat:wght@300;400;600;800&family=Oswald:wght@300;400;600&display=swap');

:root {
  --color-text: #fff;
  --color-text-dark: #fff;
  --color-yellow: #FFF050;
  --color-yellow-dark: #FFCE58;
  --color-blue: #5165F6;
  --color-purple: #A341FF;
  --color-purple-dark: #5F2CB0;
  --color-navbar: #271335;
}

.App {
  text-align: center;
}

.luckyguy {
  font-family: "Luckiest Guy";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}