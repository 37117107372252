@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Montserrat:wght@300;400;600;800&family=Oswald:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.phishing_warning {
    width: 100vw;
    height: 85px;
    background: #1b1b1b;
    display: flex;
    justify-content: center;
    align-items: center;

    /* display: none; */
}

.phis_caf {
    width: 66px;
}

.p_warn {
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    color: #9F7EFF;
    background: #35236A;
    height: 53px;
    border-radius: 15px;
    font-size: 14px;
}

.p_warn span {
    color: var(--color-yellow);
    text-transform: uppercase;
    font-weight: bold;
}

.p_warn a {
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.c_phis {
    font-size: 30px;
    color: #656565;
    cursor: pointer;
}

.minting_sec {
    background: url(/static/media/minting_bg.d4ea52c4.png);
    width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    overflow-y: hidden;
    position: relative;
}

.mint_nav {
    padding: 20px 40px;
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: absolute;
}

.nvdiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cnct_wlt {
    width: 200px !important;
    margin-right: 20px;
}

.walletbalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wbc_Bal {
    margin-right: -30px;
    color: white;
    border: 1px solid #fff;
    padding: 0px 15px;
    padding-right: 35px;
    font-weight: bold;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.mint_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.mh2 {
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
}

.mns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--color-yellow);
}

.mbc_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal {
    position: absolute;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    display: flex;

}

.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 350px;
    background: #300661;
    z-index: 10;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
}

.buttons {
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
}

.learnDiv {
    margin-top: 20px;
    color: #dfdfdf;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.learnDiv a {
    cursor: pointer;
}

.listitem {
    padding: 0px 10px;
    height: 50px;
    background-color: #470F88;
    color: #E0D6EB;
    border-radius: 10px;
    margin: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 310px;
    box-sizing: border-box;
}

.wc_container {
    height: 130px;
    width: 310px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #470F88;
    color: #E0D6EB;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 20px;
}

.weth_link {
    font-weight: 700;
    color: white;
    cursor: pointer;
}

.insuff_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.insuff_div div {
    margin-top: 10px;
    text-align: center;
    color: #fd7272;
    font-size: 20px;
}

.insuff_div a {
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-yellow);
    color: black;
    /* text-transform: uppercase; */
    font-weight: bold;
    height: 35px;
    border-radius: 20px;
}

.wc_us {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wc_us button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    color: #dfdfdf;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wc_wt {
    font-size: 14px;
}

.wc_wa {
    font-size: 20px;
    letter-spacing: 1.5px;
    font-weight: bold;
    color: #fff;
}

.wc_links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wc_links div {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E0D6EB;
    text-decoration: none;
    cursor: pointer;
}

.wc_links a {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #dfdfdf;
    text-decoration: none;
    cursor: pointer;
}


.listitem p {
    margin-bottom: 0px !important;
}

.listitem img {
    width: 24px;
}

.upperdiv {
    width: 310px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
}

.closebtn {
    color: #dfdfdf;
    font-size: 30px;
}

.viewCafro_btn {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    color: #4c168B;
    background-color: #EDDDFF;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 10px;
}

.viewCafro_btn:hover {

    color: #4c168B;

}


.warning_Text {
    text-align: center;
    color: rgb(204, 78, 78);
}

.mns p {
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.mns_p2 {
    margin-top: -20px;
    letter-spacing: 3px;
    font-size: 28px !important;
}

.mic {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.mic .input {

    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: none;
    width: 140px;
    height: 50px;
    background-color: white;
    color: black;
    border-radius: 10px;
    text-align: center;
    font-size: 25px;
}

.micb {
    padding-bottom: 5px;
    padding-left: 2px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: var(--color-yellow);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* box-sizing: border-box; */
}

.mb_mint {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    outline: none;
    border: none;
    width: 130px;
    background: var(--color-yellow);
    color: black;
    /* text-transform: uppercase; */
    font-weight: bold;
    height: 35px;
    border-radius: 20px;

}

.lm_btn {
    opacity: 0.5;
}

.mhh3 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.mhh4 {
    font-size: 13px;
    text-align: center;
}

.mint_nav a {
    text-decoration: none;
    color: white;
}

.mint_nav a img {
    width: 30px;
}

.nc1_social {
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.nc1_social img {
    width: 40px;
}

.nc2_social {
    width: 190px;
    border-radius: 20px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #5165F6;
}

.header_logo {
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 2;
}

.header_logo img {
    width: 300px;
}

.mint_block_container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mint_block {
    margin-top: 160px;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 380px;
    border-radius: 15px;
    min-height: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    background: #382672;
    color: white;
    z-index: 2;
    box-shadow: -2px 0px 5px 8px rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: -2px 0px 5px 8px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: -2px 0px 5px 8px rgba(0, 0, 0, 0.36);
}

.mint_img {
    width: 90%;
    height: 250px;
    border: 10px solid white;
}

.mint_heading {
    width: 100%;
    font-family: 'Luckiest Guy', cursive;
    letter-spacing: 2px;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
}

.mint_caf_id {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.caf_price {
    text-transform: uppercase;
    font-weight: bold;
}

.eth_price {
    color: #4dffba;
}

.mb {
    width: 48%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-transform: uppercase; */
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.mint_btn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details_btn {
    background: #dfdfdf;
    color: black;
    letter-spacing: 1px;

}

.mint_btn {
    color: black;
    background: #FFF050;
}

#galaxy {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 900px;
    overflow-y: hidden;
}

#galaxy * {
    position: absolute;
}

#galaxy [class^="stars"] {
    top: 0%;
    /* left: 50%;
    transform: translate(-100%, 0%); */
    width: 800px;
    height: 800px;
}

#galaxy .stars-back {
    margin-left: 100px;
    background: url(/static/media/back_stars.5554885c.png);
    -webkit-animation: orbit-clock-wise 440s infinite linear;
    animation: orbit-clock-wise 440s infinite linear;
}

#galaxy .stars-middle {
    margin-left: -50px;
    background: url(/static/media/middle_stars.1f61eb30.png);
    -webkit-animation: orbit-counter-clock-wise 360s infinite linear;
    animation: orbit-counter-clock-wise 360s infinite linear;
}

#galaxy .stars-front {
    margin-left: 500px;
    background: url(/static/media/front_stars.361dcbc8.png);
    -webkit-animation: orbit-clock-wise 160s infinite linear;
    animation: orbit-clock-wise 160s infinite linear;
}

.dnp {
    text-align: center;
}

.sbtn {
    width: 150px;
    font-size: 14px;
    font-weight: bold;
}

.nnm {
    text-transform: uppercase;
}

.mintTimer {
    height: 100vh;
    width: 100vw;
    z-index: 4;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mt_overlay {
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
    width: 100%;
    z-index: 4;
}

.mt_text {
    position: absolute;
    z-index: 5;

}

.mt_heading {
    margin-top: -50px;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.mt_spinner {
    position: absolute;
    width: 50px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.th_link {
    text-align: center;
}

.wlt_address {
    width: 120px !important;
}

@media only screen and (max-width:700px) {
    .phis_caf {
        width: 50px;
    }

    .p_warn {
        margin: 0px 10px;
        padding: 0px 10px;
        font-size: 12px;
        height: 70px;
        flex-direction: column;
    }


    .minting_sec {
        background: url(/static/media/mint_mob_bg.f8ef618b.png);
        height: 850px;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column-reverse;
    }

    .mint_block_container {
        margin-top: -50px;
    }

    .header_logo img {
        width: 200px;
    }

    .header_logo {
        top: 10px;
    }

    .mint_nav {
        margin-top: 670px;
        flex-direction: column-reverse;

    }

    .mint_block {
        margin-top: 0px;
        width: 350px;
    }

    .nc1_social {
        margin-top: 20px;
    }

    .nvdiv2 {
        height: 100px;
        flex-direction: column;
        justify-content: space-between;
    }

    .cnct_wlt {
        font-size: 14px;
        width: 160px !important;
        margin-right: 0px;
    }

    .wbc_Bal {
        font-size: 14px;
    }

    .mb_mint {
        width: 160px;
        font-size: 13px;
    }

    .modal {
        height: 1000px;
    }

}

@-webkit-keyframes clock-wise {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes clock-wise {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes orbit-clock-wise {
    0% {
        opacity: 0.4;
        -webkit-transform: rotate(0deg);
    }

    2% {
        opacity: 0.8;
    }

    4% {
        opacity: 0.2;
    }

    5% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.4;
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes orbit-counter-clock-wise {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes orbit-clock-wise {
    0% {
        opacity: 0.4;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    2% {
        opacity: 0.8;
    }

    4% {
        opacity: 0.2;
    }

    5% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.4;
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes orbit-counter-clock-wise {
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}
:root {
  --color-text: #fff;
  --color-text-dark: #fff;
  --color-yellow: #FFF050;
  --color-yellow-dark: #FFCE58;
  --color-blue: #5165F6;
  --color-purple: #A341FF;
  --color-purple-dark: #5F2CB0;
  --color-navbar: #271335;
}

.App {
  text-align: center;
}

.luckyguy {
  font-family: "Luckiest Guy";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.my_caf_sec {
    width: 100vw;
    background: url(/static/media/my-caf.34912094.png);
    height: 900px;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
}

.main-container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 4;
    padding-top: 150px;
    box-sizing: border-box;
}

.head-my_caf {
    color: white;
    font-size: 40px;
    letter-spacing: 2px;
    word-spacing: 5px;
    text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
    text-transform: uppercase;

}



.own_no_head {
    font-size: 30px;
    font-weight: bold;
    font-family: "Montserrat";
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--color-yellow);
    text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
}

.own_no_head2 {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--color-yellow);
    /* text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000; */
}

.my_caf_container {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    height: 600px;
    /* overflow-x: hidden; */

}

.slider {
    width: 100vw;
    /* min-height: 600px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */

}

.slick-next {
    right: 25px !important;
    z-index: 3;
}

.slick-prev {
    left: 25px !important;
    z-index: 3;
}

.slick-slider .slick-list {
    margin-left: -60px !important;
}

.swiper_wrapper {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 450px;
}

.swiper-button {
    font-size: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.next {
    width: 200px;
}

.slider_btn_container {
    margin-top: -50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
}

.slider_platform {
    width: 200px;
    position: absolute;
    top: 30%;
    -webkit-transform: translate(-0%, -50%);
            transform: translate(-0%, -50%);
}

.slider_btn {
    text-decoration: none;
    height: 40px;
    width: 150px;
    border-radius: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    margin-top: -40px;
    cursor: pointer;
    text-transform: uppercase;
}

.upr_cls {
    margin-top: -40px;
}

.slick-next:before {
    content: '❯' !important;
    font-size: 40px !important;
}

.slick-prev:before {
    content: '❮' !important;
    font-size: 40px !important;
}

.slider_sell_btn {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}

.slider_sell_btn:hover {
    color: var(--color-yellow);
}

.slider_play_btn {
    background-color: var(--color-yellow);
    color: rgba(0, 0, 0, 1);
}

.slider_play_btn:hover {
    color: rgba(0, 0, 0, 1);
}



.plat_container {
    width: 600px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pltfrm_buy {
    text-decoration: none;
    color: white;
    height: 50px;
    width: 170px;
    margin-left: -15px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.4);
}

.pltfrm_buy:hover {
    color: white;
}

.platform {
    width: 600px;
}

.pltfrm_cont {
    width: 450px;
    height: 480px;
    background: url(/static/media/platform2.8fbc0e3d.png);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.planetContainer {
    position: relative;
}

.overlay_myCaf {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_myCaf img {
    width: 50px;

}

.plnt {
    position: absolute;
    z-index: 3;

}

.blue_planet {
    width: 300px;
    top: 80px;
    right: -150px;
    -webkit-animation: clock-wise 140s linear infinite;
            animation: clock-wise 140s linear infinite;
}

.red_planet {
    width: 150px;
    top: 200px;
    -webkit-animation: clock-wise 100s linear infinite;
            animation: clock-wise 100s linear infinite;
}

.white_planet {
    width: 50px;
    top: 300px;
    left: 70%;
    -webkit-animation: clock-wise 20s linear infinite;
            animation: clock-wise 20s linear infinite;

}

@media only screen and (max-width:700px) {
    .my_caf_sec {
        height: 1000px;
    }

    .head-my_caf {
        font-size: 30px;
    }

    .slick-slider .slick-list {
        margin-left: 10px !important;
    }

    .plat_container {
        width: 350px;
    }

    .pltfrm_cont {
        width: 350px;
        height: 430px;
    }

    .upr_cls {
        margin-top: 0px;
    }

    .slider_platform {
        width: 150px;
    }

    .blue_planet {
        width: 200px;
    }

    .red_planet {
        width: 100px;
    }

    .own_no_head {
        width: 100vw;
        text-align: center;
        font-size: 20px;
    }

    .own_no_head2 {
        width: 100vw;
        text-align: center;
        font-size: 16px;
    }

    .platform {
        width: 500px;
    }

    .main-container {
        overflow-x: hidden;
    }

    .mint_nav2 {
        margin-top: 800px;
        flex-direction: column;
    }

    .nc1_social2 {
        margin-bottom: 10px;
    }
}

@-webkit-keyframes clock-wise {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes clock-wise {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
