.my_caf_sec {
    width: 100vw;
    background: url('../../media/bgs/my-caf.png');
    height: 900px;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
}

.main-container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 4;
    padding-top: 150px;
    box-sizing: border-box;
}

.head-my_caf {
    color: white;
    font-size: 40px;
    letter-spacing: 2px;
    word-spacing: 5px;
    text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
    text-transform: uppercase;

}



.own_no_head {
    font-size: 30px;
    font-weight: bold;
    font-family: "Montserrat";
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--color-yellow);
    text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
}

.own_no_head2 {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--color-yellow);
    /* text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000; */
}

.my_caf_container {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    height: 600px;
    /* overflow-x: hidden; */

}

.slider {
    width: 100vw;
    /* min-height: 600px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */

}

.slick-next {
    right: 25px !important;
    z-index: 3;
}

.slick-prev {
    left: 25px !important;
    z-index: 3;
}

.slick-slider .slick-list {
    margin-left: -60px !important;
}

.swiper_wrapper {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 450px;
}

.swiper-button {
    font-size: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.next {
    width: 200px;
}

.slider_btn_container {
    margin-top: -50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
}

.slider_platform {
    width: 200px;
    position: absolute;
    top: 30%;
    transform: translate(-0%, -50%);
}

.slider_btn {
    text-decoration: none;
    height: 40px;
    width: 150px;
    border-radius: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    margin-top: -40px;
    cursor: pointer;
    text-transform: uppercase;
}

.upr_cls {
    margin-top: -40px;
}

.slick-next:before {
    content: '❯' !important;
    font-size: 40px !important;
}

.slick-prev:before {
    content: '❮' !important;
    font-size: 40px !important;
}

.slider_sell_btn {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}

.slider_sell_btn:hover {
    color: var(--color-yellow);
}

.slider_play_btn {
    background-color: var(--color-yellow);
    color: rgba(0, 0, 0, 1);
}

.slider_play_btn:hover {
    color: rgba(0, 0, 0, 1);
}



.plat_container {
    width: 600px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pltfrm_buy {
    text-decoration: none;
    color: white;
    height: 50px;
    width: 170px;
    margin-left: -15px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.4);
}

.pltfrm_buy:hover {
    color: white;
}

.platform {
    width: 600px;
}

.pltfrm_cont {
    width: 450px;
    height: 480px;
    background: url("../../media/elements/platform2.png");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.planetContainer {
    position: relative;
}

.overlay_myCaf {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_myCaf img {
    width: 50px;

}

.plnt {
    position: absolute;
    z-index: 3;

}

.blue_planet {
    width: 300px;
    top: 80px;
    right: -150px;
    animation: clock-wise 140s linear infinite;
}

.red_planet {
    width: 150px;
    top: 200px;
    animation: clock-wise 100s linear infinite;
}

.white_planet {
    width: 50px;
    top: 300px;
    left: 70%;
    animation: clock-wise 20s linear infinite;

}

@media only screen and (max-width:700px) {
    .my_caf_sec {
        height: 1000px;
    }

    .head-my_caf {
        font-size: 30px;
    }

    .slick-slider .slick-list {
        margin-left: 10px !important;
    }

    .plat_container {
        width: 350px;
    }

    .pltfrm_cont {
        width: 350px;
        height: 430px;
    }

    .upr_cls {
        margin-top: 0px;
    }

    .slider_platform {
        width: 150px;
    }

    .blue_planet {
        width: 200px;
    }

    .red_planet {
        width: 100px;
    }

    .own_no_head {
        width: 100vw;
        text-align: center;
        font-size: 20px;
    }

    .own_no_head2 {
        width: 100vw;
        text-align: center;
        font-size: 16px;
    }

    .platform {
        width: 500px;
    }

    .main-container {
        overflow-x: hidden;
    }

    .mint_nav2 {
        margin-top: 800px;
        flex-direction: column;
    }

    .nc1_social2 {
        margin-bottom: 10px;
    }
}

@keyframes clock-wise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}